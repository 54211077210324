export const redirectURL = {
  "/account": {
    old: "https://www.gogreendrop.com/account",
    new: "https://scheduling.gogreendrop.com/account",
  },
  "/account/profile": {
    old: "https://www.gogreendrop.com/account/profile",
    new: "https://scheduling.gogreendrop.com/account",
  },
  "/pick-up": {
    old: "https://www.gogreendrop.com/pick-up",
    new: "https://scheduling.gogreendrop.com/pickup",
  },
  "/find-a-location": [
    {
      old: "https://www.gogreendrop.ca/find-a-location",
      new: "https://locations.gogreendrop.com/?lang=en&country=ca",
    },
    {
      old: "https://www.gogreendrop.com/find-a-location",
      new: "https://locations.gogreendrop.com",
    },
  ],
  "/fr/trouver-un-sites-de-dons": {
    old: "https://www.gogreendrop.ca/fr/trouver-un-sites-de-dons",
    new: "https://locations.gogreendrop.com/?lang=fr&country=ca",
  },
  "/find-a-location/88th-avenue-walgreens-anchorage-ak": {
    old: "https://www.gogreendrop.com/find-a-location/88th-avenue-walgreens-anchorage-ak",
    new: "https://locations.gogreendrop.com/ak/anchorage/greendrop-donation-center-4691.html",
  },
  "/find-a-location/lions-club-sports-park-eagle-river-ak": {
    old: "https://www.gogreendrop.com/find-a-location/lions-club-sports-park-eagle-river-ak",
    new: "https://locations.gogreendrop.com/ak/eagleriver/greendrop-donation-center-4690.html",
  },

  "/find-a-location/three-bears-sg-chugiak-ak": {
    old: "https://www.gogreendrop.com/find-a-location/three-bears-sg-chugiak-ak",
    new: "https://locations.gogreendrop.com/ak/chugiak/greendrop-donation-center-4692.html",
  },
  "find-a-location/three-bears-sg-palmer-ak": {
    old: "https://www.gogreendrop.com/find-a-location/three-bears-sg-palmer-ak",
    new: "https://locations.gogreendrop.com/ak/palmer/greendrop-donation-center-4693.html",
  },
  "/find-a-location/three-bears-sg-wasilla-ak": {
    old: "https://www.gogreendrop.com/find-a-location/three-bears-sg-wasilla-ak",
    new: "https://locations.gogreendrop.com/ak/wasilla/greendrop-donation-center-4694.html",
  },
  "/find-a-location/wilmington": {
    old: "https://www.gogreendrop.com/find-a-location/wilmington",
    new: "https://locations.gogreendrop.com/de/wilmington/greendrop-donation-center-4628.html",
  },
  "/find-a-location/friendshipheights": {
    old: "https://www.gogreendrop.com/find-a-location/friendshipheights",
    new: "https://locations.gogreendrop.com/dc/washington/greendrop-donation-center-4611.html",
  },
  "/find-a-location/ewa-pointe-marketplace-ewa-beach-hi": {
    old: "https://www.gogreendrop.com/find-a-location/ewa-pointe-marketplace-ewa-beach-hi",
    new: "https://locations.gogreendrop.com/hi/ewabeach/greendrop-donation-center-4688.html",
  },
  "/find-a-location/hawaii-kai-honolulu-hi": {
    old: "https://www.gogreendrop.com/find-a-location/hawaii-kai-honolulu-hi",
    new: "https://locations.gogreendrop.com/hi/honolulu/greendrop-donation-center-4651.html",
  },
  "/find-a-location/hawaii-kai-towne-center-honolulu-hi": {
    old: "https://www.gogreendrop.com/find-a-location/hawaii-kai-towne-center-honolulu-hi",
    new: "https://locations.gogreendrop.com/hi/honolulu/greendrop-donation-center-4712.html",
  },
  "/find-a-location/ka-makana-alii-kapolei-hi": {
    old: "https://www.gogreendrop.com/find-a-location/ka-makana-alii-kapolei-hi",
    new: "https://locations.gogreendrop.com/hi/kapolei/greendrop-donation-center-4645.html",
  },
  "/find-a-location/kailua-hi": {
    old: "https://www.gogreendrop.com/find-a-location/kailua-hi",
    new: "https://locations.gogreendrop.com/hi/kailua/greendrop-donation-center-4653.html",
  },
  "/find-a-location/kalihi-honolulu-hi": {
    old: "https://www.gogreendrop.com/find-a-location/kalihi-honolulu-hi",
    new: "https://locations.gogreendrop.com/hi/honolulu/greendrop-donation-center-4683.html",
  },
  "/find-a-location/manoa-marketplace-honolulu-hi": {
    old: "https://www.gogreendrop.com/find-a-location/manoa-marketplace-honolulu-hi",
    new: "https://locations.gogreendrop.com/hi/honolulu/greendrop-donation-center-4714.html",
  },
  "/find-a-location/mililani-hi": {
    old: "https://www.gogreendrop.com/find-a-location/mililani-hi",
    new: "https://locations.gogreendrop.com/hi/mililani/greendrop-donation-center-4711.html",
  },
  "find-a-location/kapolei-hi": {
    old: "https://www.gogreendrop.com/find-a-location/kapolei-hi",
    new: "https://locations.gogreendrop.com/hi/kapolei/greendrop-donation-center-4689.html",
  },
  "/find-a-location/pearlridge-center-aiea-hi": {
    old: "https://www.gogreendrop.com/find-a-location/pearlridge-center-aiea-hi",
    new: "https://locations.gogreendrop.com/hi/aiea/greendrop-donation-center-4713.html",
  },
  "/find-a-location/stadium-marketplace-honolulu-hi": {
    old: "https://www.gogreendrop.com/find-a-location/stadium-marketplace-honolulu-hi",
    new: "https://locations.gogreendrop.com/hi/honolulu/greendrop-donation-center-4648.html",
  },
  "/find-a-location/windward-mall-kaneohe-hi": {
    old: "https://www.gogreendrop.com/find-a-location/windward-mall-kaneohe-hi",
    new: "https://locations.gogreendrop.com/hi/kaneohe/greendrop-donation-center-4674.html",
  },
  "/find-a-location/northbrook-il": {
    old: "https://www.gogreendrop.com/find-a-location/northbrook-il",
    new: "https://locations.gogreendrop.com/il/northbrook/greendrop-donation-center-4678.html",
  },
  "/find-a-location/arundelmillscir": {
    old: "https://www.gogreendrop.com/find-a-location/arundelmillscir",
    new: "https://locations.gogreendrop.com/md/hanover/greendrop-donation-center-4643.html",
  },
  "/find-a-location/bel-air-md": {
    old: "https://www.gogreendrop.com/find-a-location/bel-air-md",
    new: "https://locations.gogreendrop.com/md/belair/greendrop-donation-center-4660.html",
  },
  "/find-a-location/cloppers-mill-germantown-md": {
    old: "https://www.gogreendrop.com/find-a-location/cloppers-mill-germantown-md",
    new: "https://locations.gogreendrop.com/md/germantown/greendrop-donation-center-4646.html",
  },
  "/find-a-location/essex": {
    old: "https://www.gogreendrop.com/find-a-location/essex",
    new: "https://locations.gogreendrop.com/md/essex/greendrop-donation-center-4638.html",
  },
  "/find-a-location/laurel-plaza": {
    old: "https://www.gogreendrop.com/find-a-location/laurel-plaza",
    new: "https://locations.gogreendrop.com/md/laurel/greendrop-donation-center-4600.html",
  },
  "/find-a-location/towson-md": {
    old: "https://www.gogreendrop.com/find-a-location/towson-md",
    new: "https://locations.gogreendrop.com/md/towson/greendrop-donation-center-4617.html",
  },
  "/find-a-location/whitemarshmd": {
    old: "https://www.gogreendrop.com/find-a-location/whitemarshmd",
    new: "https://locations.gogreendrop.com/md/baltimore/greendrop-donation-center-4610.html",
  },
  "/find-a-location/york-md": {
    old: "https://www.gogreendrop.com/find-a-location/york-md",
    new: "https://locations.gogreendrop.com/md/baltimore/greendrop-donation-center-4619.html",
  },
  "/find-a-location/centennial-center-las-vegas-nv": {
    old: "https://www.gogreendrop.com/find-a-location/centennial-center-las-vegas-nv",
    new: "https://locations.gogreendrop.com/nv/lasvegas/greendrop-donation-center-4696.html",
  },
  "/find-a-location/eastern-beltway-las-vegas-nv": {
    old: "https://www.gogreendrop.com/find-a-location/eastern-beltway-las-vegas-nv",
    new: "https://locations.gogreendrop.com/nv/lasvegas/greendrop-donation-center-4697.html",
  },
  "/find-a-location/eastern-springs-village-las-vegas-nv": {
    old: "https://www.gogreendrop.com/find-a-location/eastern-springs-village-las-vegas-nv",
    new: "https://locations.gogreendrop.com/nv/lasvegas/greendrop-donation-center-4682.html",
  },
  "/find-a-location/green-valley-crossing-henderson-nv": {
    old: "https://www.gogreendrop.com/find-a-location/green-valley-crossing-henderson-nv",
    new: "https://locations.gogreendrop.com/nv/henderson/greendrop-donation-center-4684.html",
  },
  "/find-a-location/lv-premium-outlets-nv": {
    old: "https://www.gogreendrop.com/find-a-location/lv-premium-outlets-nv",
    new: "https://locations.gogreendrop.com/nv/lasvegas/greendrop-donation-center-4681.html",
  },
  "/find-a-location/shadow-hills-las-vegas-nv": {
    old: "https://www.gogreendrop.com/find-a-location/shadow-hills-las-vegas-nv",
    new: "https://locations.gogreendrop.com/nv/lasvegas/greendrop-donation-center-4675.html",
  },
  "/find-a-location/shadow-mountain-las-vegas-nv": {
    old: "https://www.gogreendrop.com/find-a-location/shadow-mountain-las-vegas-nv",
    new: "https://locations.gogreendrop.com/nv/lasvegas/greendrop-donation-center-4650.html",
  },
  "/find-a-location/tropicana-las-vegas-nv": {
    old: "https://www.gogreendrop.com/find-a-location/tropicana-las-vegas-nv",
    new: "https://locations.gogreendrop.com/nv/lasvegas/greendrop-donation-center-4657.html",
  },
  "/find-a-location/metuchen": {
    old: "https://www.gogreendrop.com/find-a-location/metuchen",
    new: "https://locations.gogreendrop.com/nj/metuchen/greendrop-donation-center-4631.html",
  },
  "/find-a-location/newport-plaza-jersey-city-nj": {
    old: "https://www.gogreendrop.com/find-a-location/newport-plaza-jersey-city-nj",
    new: "https://locations.gogreendrop.com/nj/jerseycity/greendrop-donation-center-4654.html",
  },
  "/find-a-location/springfield": {
    old: "https://www.gogreendrop.com/find-a-location/springfield",
    new: "https://locations.gogreendrop.com/nj/springfield/greendrop-donation-center-4632.html",
  },
  "/find-a-location/totowanj": {
    old: "https://www.gogreendrop.com/find-a-location/totowanj",
    new: "https://locations.gogreendrop.com/nj/totowa/greendrop-donation-center-4635.html",
  },
  "/find-a-location/cheektowaga-ny": {
    old: "https://www.gogreendrop.com/find-a-location/cheektowaga-ny",
    new: "https://locations.gogreendrop.com/ny/cheektowaga/greendrop-donation-center-4715.html",
  },
  "/find-a-location/depew-ny": {
    old: "https://www.gogreendrop.com/find-a-location/depew-ny",
    new: "https://locations.gogreendrop.com/ny/depew/greendrop-donation-center-4685.html",
  },
  "/find-a-location/orchard-park-ny": {
    old: "https://www.gogreendrop.com/find-a-location/orchard-park-ny",
    new: "https://locations.gogreendrop.com/ny/orchardpark/greendrop-donation-center-4677.html",
  },
  "/find-a-location/lake-grove-ny": {
    old: "https://www.gogreendrop.com/find-a-location/lake-grove-ny",
    new: "https://locations.gogreendrop.com/ny/lakegrove/greendrop-donation-center-4644.html",
  },
  "/find-a-location/huntington-station-ny": {
    old: "https://www.gogreendrop.com/find-a-location/huntington-station-ny",
    new: "https://locations.gogreendrop.com/ny/huntingtonstation/greendrop-donation-center-4642.html",
  },
  "/find-a-location/white-plains": {
    old: "https://www.gogreendrop.com/find-a-location/white-plains",
    new: "https://locations.gogreendrop.com/ny/whiteplains/greendrop-donation-center-4636.html",
  },
  "/find-a-location/aston": {
    old: "https://www.gogreendrop.com/find-a-location/aston",
    new: "https://locations.gogreendrop.com/pa/aston/greendrop-donation-center-4621.html",
  },
  "/find-a-location/bensalem-pa": {
    old: "https://www.gogreendrop.com/find-a-location/bensalem-pa",
    new: "https://locations.gogreendrop.com/pa/bensalem/greendrop-donation-center-4656.html",
  },
  "/find-a-location/brookhaven-pa": {
    old: "https://www.gogreendrop.com/find-a-location/brookhaven-pa",
    new: "https://locations.gogreendrop.com/pa/brookhaven/greendrop-donation-center-4672.html",
  },
  "/find-a-location/broomall": {
    old: "https://www.gogreendrop.com/find-a-location/broomall",
    new: "https://locations.gogreendrop.com/pa/broomall/greendrop-donation-center-4602.html",
  },
  "/find-a-location/doylestown": {
    old: "https://www.gogreendrop.com/find-a-location/doylestown",
    new: "https://locations.gogreendrop.com/pa/doylestown/greendrop-donation-center-4604.html",
  },
  "/find-a-location/exton": {
    old: "https://www.gogreendrop.com/find-a-location/exton",
    new: "https://locations.gogreendrop.com/pa/exton/greendrop-donation-center-4603.html",
  },
  "/find-a-location/frazer-malvern": {
    old: "https://www.gogreendrop.com/find-a-location/frazer-malvern",
    new: "https://locations.gogreendrop.com/pa/malvern/greendrop-donation-center-4622.html",
  },
  "/find-a-location/king-of-prussia-pa": {
    old: "https://www.gogreendrop.com/find-a-location/king-of-prussia-pa",
    new: "https://locations.gogreendrop.com/pa/kingofprussia/greendrop-donation-center-4658.html",
  },
  "/find-a-location/media": {
    old: "https://www.gogreendrop.com/find-a-location/media",
    new: "https://locations.gogreendrop.com/pa/media/greendrop-donation-center-4623.html",
  },
  "/find-a-location/narberth": {
    old: "https://www.gogreendrop.com/find-a-location/narberth",
    new: "https://locations.gogreendrop.com/pa/narberth/greendrop-donation-center-4624.html",
  },
  "/find-a-location/newtown": {
    old: "https://www.gogreendrop.com/find-a-location/newtown",
    new: "https://locations.gogreendrop.com/pa/newtownsquare/greendrop-donation-center-4634.html",
  },
  "/find-a-location/paoli": {
    old: "https://www.gogreendrop.com/find-a-location/paoli",
    new: "https://locations.gogreendrop.com/pa/paoli/greendrop-donation-center-4633.html",
  },
  "/find-a-location/philadelphia-premium-outlets-pottstown-pa": {
    old: "https://www.gogreendrop.com/find-a-location/philadelphia-premium-outlets-pottstown-pa",
    new: "https://locations.gogreendrop.com/pa/pottstown/greendrop-donation-center-4661.html",
  },
  "/find-a-location/phoenixville": {
    old: "https://www.gogreendrop.com/find-a-location/phoenixville",
    new: "https://locations.gogreendrop.com/pa/phoenixville/greendrop-donation-center-4601.html",
  },
  "/find-a-location/plymouth": {
    old: "https://www.gogreendrop.com/find-a-location/plymouth",
    new: "https://locations.gogreendrop.com/pa/conshohocken/greendrop-donation-center-4605.html",
  },
  "/find-a-location/west-chester": {
    old: "https://www.gogreendrop.com/find-a-location/west-chester",
    new: "https://locations.gogreendrop.com/pa/westchester/greendrop-donation-center-4625.html",
  },
  "/find-a-location/west-norriton": {
    old: "https://www.gogreendrop.com/find-a-location/west-norriton",
    new: "https://locations.gogreendrop.com/pa/norristown/greendrop-donation-center-4629.html",
  },
  "/find-a-location/willow-grove": {
    old: "https://www.gogreendrop.com/find-a-location/willow-grove",
    new: "https://locations.gogreendrop.com/pa/willowgrove/greendrop-donation-center-4630.html",
  },
  "/find-a-location/alexandria": {
    old: "https://www.gogreendrop.com/find-a-location/alexandria",
    new: "https://locations.gogreendrop.com/va/alexandria/greendrop-donation-center-4627.html",
  },
  "/find-a-location/braemar-bristow-va": {
    old: "https://www.gogreendrop.com/find-a-location/braemar-bristow-va",
    new: "https://locations.gogreendrop.com/va/bristow/greendrop-donation-center-4614.html",
  },
  "/find-a-location/centreville": {
    old: "https://www.gogreendrop.com/find-a-location/centreville",
    new: "https://locations.gogreendrop.com/va/centreville/greendrop-donation-center-4606.html",
  },
  "/find-a-location/gsvfd": {
    old: "https://www.gogreendrop.com/find-a-location/gsvfd",
    new: "https://locations.gogreendrop.com/va/springfield/greendrop-donation-center-4609.html",
  },
  "/find-a-location/greenbriar": {
    old: "https://www.gogreendrop.com/find-a-location/greenbriar",
    new: "https://locations.gogreendrop.com/va/fairfax/greendrop-donation-center-4608.html",
  },
  "/find-a-location/herndon-va": {
    old: "https://www.gogreendrop.com/find-a-location/herndon-va",
    new: "https://locations.gogreendrop.com/va/herndon/greendrop-donation-center-4613.html",
  },
  "/find-a-location/kings-park-springfield-va": {
    old: "https://www.gogreendrop.com/find-a-location/kings-park-springfield-va",
    new: "https://locations.gogreendrop.com/va/springfield/greendrop-donation-center-4655.html",
  },
  "/find-a-location/lorton": {
    old: "https://www.gogreendrop.com/find-a-location/lorton",
    new: "https://locations.gogreendrop.com/va/lorton/greendrop-donation-center-4616.html",
  },
  "/find-a-location/manchester-lakes-franconia-va": {
    old: "https://www.gogreendrop.com/find-a-location/manchester-lakes-franconia-va",
    new: "https://locations.gogreendrop.com/va/franconia/greendrop-donation-center-4647.html",
  },
  "/find-a-location/saratoga": {
    old: "https://www.gogreendrop.com/find-a-location/saratoga",
    new: "https://locations.gogreendrop.com/va/springfield/greendrop-donation-center-4607.html",
  },
  "/find-a-location/shops-at-county-center-manassas-va": {
    old: "https://www.gogreendrop.com/find-a-location/shops-at-county-center-manassas-va",
    new: "https://locations.gogreendrop.com/va/manassas/greendrop-donation-center-4649.html",
  },
  "/find-a-location/stoneridge": {
    old: "https://www.gogreendrop.com/find-a-location/stoneridge",
    new: "https://locations.gogreendrop.com/va/aldie/greendrop-donation-center-4615.html",
  },
  "/find-a-location/universitymall-va": {
    old: "https://www.gogreendrop.com/find-a-location/universitymall-va",
    new: "https://locations.gogreendrop.com/va/fairfax/greendrop-donation-center-4612.html",
  },
  "/find-a-location/woodbridge": {
    old: "https://www.gogreendrop.com/find-a-location/woodbridge",
    new: "https://locations.gogreendrop.com/va/woodbridge/greendrop-donation-center-4640.html",
  },
  "/careers": {
    old: "https://www.gogreendrop.com/careers",
    new: "https://careers.savers.com/search/jobs?location=&q=GreenDrop",
  },
  "/what-we-do": {
    old: "https://www.gogreendrop.com/what-we-do",
    new: "https://www.gogreendrop.com/about-us",
  },
  "/how-it-works": {
    old: "https://www.gogreendrop.com/how-it-works",
    new: "https://www.gogreendrop.com/about-us",
  },
  "/nonprofit-and-charity-partners": {
    old: "https://www.gogreendrop.com/nonprofit-and-charity-partners",
    new: "https://www.gogreendrop.com/partners",
  },
  "/about-donations": {
    old: "https://www.gogreendrop.com/about-donations",
    new: "https://www.gogreendrop.com/about-us",
  },
  "/faqs": {
    old: "https://www.gogreendrop.com/faqs",
    new: "https://www.gogreendrop.com/contact-us",
  },
  "/ServiceArea": {
    old: "https://www.gogreendrop.com/ServiceArea",
    new: "https://locations.gogreendrop.com",
  },
};
